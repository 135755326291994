<template>
  <div
    class="mt-0 pt-0 loginpage-container"
    style="height: 100vh; min-height: 750px"
  >
    <Loader v-if="isLoading" />
    <div class="login-header">
      <img
        class="justify-start ml-5"
        src="../assets/images/Medvantx_Logo.svg"
      />
      <span class="headerName">Patient Assistance Portal</span>
    </div>
    <div class="main-content d-flex justify-end">
      <div class="d-flex justify-center align-start">
        <v-container class="content-container">
          <!-- <div > -->
            <div class="d-flex justify-center flex-column align-center">
              <h2 style="font-size: 32px;">Access the Portal</h2>
              <div style="font-weight: 400; font-size: 20px;">Login here for the medvantx Access Portal.</div>
              <div>
                <v-btn
                  class="btn--color--blue login-button" 
                  light
                  @click="signIn"
                  >Login</v-btn
                >
              </div>
            </div>
            <hr>
            <div>
              <div class="d-flex mid-content">
                <div class="flex-item">
                  <h3>Download & Print</h3>
                  <div class="para-content">These forms can be download & printed & require an original signature.</div>
                </div>
                <div class="flex-item default--color">
                  <div class="d-flex justify-end align-end">
                    <span class="cursor--pointer" @click="downloadForm()" style="font-weight: 500;">
                    <v-icon class="default--color">mdi-tray-arrow-down</v-icon>
                    2022 Enrollment Form
                  </span>
                  </div>
                </div>
              </div>
              <div>
                <div class="d-flex flex-row align-center justify-center" style="margin: 24px;">
                  <span class="logo-text">Powered By</span>
                  <v-img
                    contain
                    position="left"
                    max-width="75px"
                    max-height="30px"
                    src="../assets/images/vCare-logo.png"
                  ></v-img>
                </div>
              </div>
            </div>
          <!-- </div> -->
        </v-container>
      </div>
    </div>
    <div class="login-footer d-flex justify-center align-center" fixed color="white">
        <div style="font-weight: 400;"><strong>Fax:</strong> 844-737-3493 	&nbsp; | 	&nbsp; <strong>Email:</strong> hq@medvantx.com 	&nbsp; | 	&nbsp; <strong>Mail:</strong> 12680 High Bluff Dr.,Suite 150 San Diego, CA 92130</div>
    </div>
    <custom-alert
      v-show="lockedIn"
      headerText="Alert !!"
      status="warning"
      statusText="Some other user is using this application from the same ID"
      @confirm="onConfirm"
      @close="onConfirm"
    />
  </div>
</template>
<script>
import CustomAlert from "../components/CustomAlert.vue";
import Loader from "../components/Loader.vue";
import axios from "axios";
export default {
  data: () => ({
    bodyHeight: "height:auto",
    lockedIn: false,
    isLoading: false,
  }),
  components: {
    CustomAlert,
    Loader,
  },
  methods: {
    downloadForm() {
      var link = document.createElement("a");
      link.href = "/pap-form.pdf";
      link.download = "Pap_Form";
      link.click();
    },

    callSetTimeStamp() {
      let data = {
        workEmail: JSON.parse(
          sessionStorage.getItem("account")
        ).username.toLowerCase(),
      };
      setInterval(() => {
        axios.post(`${process.env.VUE_APP_BASE_URL}User/setTimeStamp`, data);
      }, 50000);
    },

    signIn() {
      sessionStorage.clear();
      this.$msal
        .loginPopup({
          scopes: [`user.read`],
        })
        .then((res) => {
          sessionStorage.setItem("account", JSON.stringify(res.account));
          sessionStorage.setItem("accessToken", res.accessToken);
          sessionStorage.setItem("expiresTime", res.expiresOn);
          this.isLoading = true;
          this.$store.dispatch("getCurrentUser").then(() => this.getData());
        });
    },

    getData() {
      let data = {
        workEmail: JSON.parse(
          sessionStorage.getItem("account")
        ).username.toLowerCase(),
      };
      this.axios
        .post(`User/setIsCurrentlyLoggedIn`, data)
        .then((res) => {
          this.isLoading = false;
          if (res.data.isLoggedIn) {
            this.lockedIn = true;
            sessionStorage.clear();
          } else {
            this.$router.push("/dashBoard");
            this.getLockedInStatus();
            let data = {
              workEmail: JSON.parse(
                sessionStorage.getItem("account")
              ).username.toLowerCase(),
            };
            axios
              .post(`${process.env.VUE_APP_BASE_URL}User/setTimeStamp`, data)
              .then(() => this.callSetTimeStamp());
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    getLockedInStatus() {
      let data = {
        workEmail: JSON.parse(
          sessionStorage.getItem("account")
        ).username.toLowerCase(),
        isLoggedIn: true,
      };
      this.axios.post(
        `${process.env.VUE_APP_BASE_URL}/User/setIsCurrentlyLoggedIn`,
        data
      );
    },
    onConfirm() {
      this.lockedIn = false;
      sessionStorage.clear();
    },
  },
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 3px;
}
* {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.layout--without--sidenav {
  padding: 0px 0px !important;
}
.divider-text {
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 50%;
  padding: 7px;
  left: 0px;
  position: absolute;
}
.headerName {
  font-size: 26px;
  font-weight: 400;
  color: white;
  margin-left: 30%;
}
.login-header {
  display: flex;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid black;
  padding: 0.1%;
  background-color: #0c0c33;
}
.login-left-image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
}
.login-left-img {
  height: 100%;
  width: 100%;
}
.download-icon {
  background-color: black;
  padding: 15px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.download-text {
  background-color: #0057ed;
  padding: 15px;
  color: white;
  position: absolute;
  left: 53px;
  white-space: nowrap;
}
.divider-line {
  max-height: 80%;
  min-height: 80%;
  position: absolute;
  top: 50px;
  left: 18px;
}
.login-footer {
  height: 36px !important;
}
.content-container{
  background-color: #ffffff;
  margin-top: 5rem;
  margin-right: 6rem;
  margin-left: 6rem;  
  max-width: 600px;
  padding: 24px;
}
.flex-item{
  flex: 1;
  align-self: center;
}
.mid-content{
  margin: 36px auto;
}
.para-content{
  font-weight: 400;
  line-height: 17px;
  font-size: 14px;
}
.logo-text{
  font-size: 15px;
  font-weight: 300;
}
.login-button{
  width: 220px;
  height: 46px !important;
  padding: 13px 0px;
  margin: 30px;
}
.main-content {
  /* background-color: #cccccc; */
  height: 90% !important;
  width: 100% !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  /* background: rgb(224,223,222); */
/* background: linear-gradient(39deg, rgba(224,223,222,.5) 0%, rgba(112,109,93,.5) 60%, rgba(176,64,0,.5) 93%, rgba(241,240,245,.5) 93%); */
background-image: url("../assets/images/loginLeftImg.svg");
}
.login-content-container {
  background-color: rgba(242, 243, 248, 0.9);
  border-radius: 4px;
  max-width: 500px;
  /* padding: 20px 0 20px 0; */
  border: 1.5px solid slategrey;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
@media screen and (max-width: 1200px) {
  .headerName {
    margin-left: 20%;
  }
}
/* @media screen and (max-width: 862px) {
  
} */
</style>
