<template>
  <v-app>
    <v-row justify="center">
      <v-dialog v-model="dialogBox" max-width="600" persistent>
        <template v-slot:default="dialog">
          <v-card>
            <div class="d-flex">
              <v-toolbar class="elevation-0">
                <div>
                  <h3>Attach Documents</h3>
                </div>
                <v-spacer></v-spacer>
                <div>
                  <v-icon @click="closePopup(dialog)">mdi-close</v-icon>
                </div>
              </v-toolbar>
            </div>
            <v-divider></v-divider>
            <v-card-text
              class="pt--3"
              style="height: 30rem; overflow-y: scroll"
            >
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col>
                    <div>
                      <h3>
                        Select Documents
                        <span class="red--bold">*</span>
                      </h3>
                    </div>
                  </v-col>
                  <v-col>
                    <div>
                      <h3>
                        Attach Documents
                        <span class="red--bold">*</span>
                      </h3>
                    </div>
                  </v-col>
                </v-row>
                <div v-for="(item, index) in uploadArr" :key="index">
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="item.selectedTemplate"
                        outlined
                        dense
                        :items="templateData"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :hide-details="auto"
                        :rules="requiredRules"
                        :disabled="item.files? true : false"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-file-input
                        v-model="item.files"
                        label="File input"
                        placeholder="Select your files"
                        dense
                        outlined
                        append-icon="mdi-upload"
                        prepend-icon=""
                        :accept=" item.selectedTemplate === 'Prescription'? 'application/pdf':'application/pdf, image/*'"
                        :hide-details="auto"
                        :rules="requiredRules"
                        @change="uploadFile(item, index)"
                      ></v-file-input>
                      <div v-if="item.loader == true" class="mt--1">
                        <v-progress-linear
                          color="#0057ED"
                          indeterminate
                          rounded
                          height="6"
                        ></v-progress-linear>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <v-row>
                  <v-col class="d-flex justify-end">
                    <v-btn class="mx-2" fab small dark color="#0057ED">
                      <v-icon dark @click="addFields"> mdi-plus </v-icon>
                    </v-btn>
                    <v-btn class="mx-2" fab small dark color="#0057ED">
                      <v-icon dark @click="removeFields"> mdi-delete </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-row class="d-flex justify-end pt--3">
              <v-btn
                class="ma-2 btn--cancel"
                outlined
                x-large
                shaped
                depressed
                @click="closePopup(dialog)"
                >Cancel</v-btn
              >
              <v-btn
                class="ma-2 btn--send"
                outlined
                x-large
                shaped
                depressed
                @click="uploadDocuments"
                >Upload</v-btn
              >
            </v-row>

            <!-- <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Close</v-btn>
            </v-card-actions> -->
          </v-card>
        </template>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  name: "dialog-box",
  props: {
    enrollmentId: {
      type: String,
    },
  },
  data() {
    return {
      dialogBox: true,
      uploadArr: [
        {
          selectedTemplate: "",
          files: "",
          loader: false,
        },
      ],
      auto: "",
      valid: "",
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  props: {
    templateData: { type: Array, default: [] },
  },

  methods: {
    closePopup(dialog) {
      dialog.value = false;
      this.$emit("close");
    },
    getDocumentsName() {
      let headers = {
        authorization: sessionStorage.getItem("accessToken"),
      };
      axios
        .get(
          `${process.env.VUE_APP_BASE_URL}/Shared/getConfig?payload=fileTypes`,
          { headers }
        )
        .then((res) => {
          this.templateData = res.data.data.map((prop) => {
            return prop.fileType;
          });
        });
    },
    uploadDocuments() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        let counter = 0;
        this.uploadArr.map(async (item, index) => {
          this.uploadArr[index].loader = true;
          const fd = new FormData();
          fd.append("file", item.files);
          fd.append("type", item.selectedTemplate);
          let params = {
            enrollmentId: sessionStorage.getItem("papEnrollmentId"),
            fileType: item.selectedTemplate,
          };
          let headers = {
            authorization: sessionStorage.getItem("accessToken"),
          };
          await axios
            .post(`${process.env.VUE_APP_BASE_URL}/Enrollment/upload`, fd, {
              headers,
              params,
            })
            .then((res) => {
              counter++;
              if (res.data.data.msg === "sucess") {
                this.uploadArr[index].loader = false;
              }
              if (counter == this.uploadArr.length) {
                this.$emit("uploadedDocument", this.uploadArr);
              }
            })
            .catch((err) => {
              this.uploadArr[index].loader = false;
              if (counter == this.uploadArr.length) {
                this.$emit("uploadedDocument", this.uploadArr);
              }
            });
        });
      }
    },
    addFields() {
      if (this.$refs.form.validate()) {
        this.uploadArr.push({
          selectedTemplate: "",
          files: "",
          loader: false,
        });
      }
    },
    removeFields() {
      if (this.uploadArr.length > 1) {
        this.uploadArr.pop({
          selectedTemplate: "",
          files: "",
          loader: false,
        });
      }
    },
    uploadFile(id, index) {},
  },
};
</script>

<style scoped lang="scss">
.v-dialog__content {
  justify-content: flex-end;
  align-items: flex-end;
}
.v-toolbar__content {
  justify-content: space-between !important;
}

.gap--1 {
  gap: 1rem;
}
.btn--cancel {
  border-color: #0057ed;
  text-transform: capitalize;
  border-radius: 8px;
  width: 40%;
}
.btn--send {
  background: #0057ed;
  color: white;
  text-transform: capitalize;
  border-radius: 8px;
  width: 40%;
}
.red--bold {
  color: red;
}
::v-deep.theme--light.v-file-input .v-file-input__text {
  color: #0057ed !important;
}
</style>
